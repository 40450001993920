import React, { useState, useEffect, Fragment, useRef } from 'react'
import Head from 'next/head'
import { createCalendlyLink } from './calendly-utils'
import styles from './CalendlyEmbed.module.css'
import Loader from '@components/loader/Loader'

declare var Calendly: any

// https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview?tab=advanced#6
function isCalendlyEvent(
  e: any,
  eventType:
    | 'profile_page_viewed'
    | 'event_type_viewed'
    | 'date_and_time_selected'
    | 'event_scheduled'
) {
  return e.data.event && e.data.event.indexOf(`calendly.${eventType}`) === 0
}

interface CalendlyEmbedProps {
  email?: string
  onScheduled: () => void
}

/**
 * Note:
 * Calendly will display a summary of the meeting after scheduling
 * @param props
 * @returns
 */
export function CalendlyEmbed(props: CalendlyEmbedProps) {
  const { email, onScheduled } = props
  const [cal, setCal] = useState<HTMLElement | null>(null)
  const [loaded, setLoaded] = useState(false)

  // HACK! See below
  const id = useRef(Math.floor(Math.random() * 1000))
  const initialized = useRef(false)
  useEffect(() => {
    if (cal !== null && typeof Calendly !== 'undefined' && !initialized.current) {
      setLoaded(true)
      Calendly.initInlineWidget({
        url: createCalendlyLink({ email: email }),
        parentElement: cal,
        prefill: {},
        utm: {},
      })

      initialized.current = true
    }
  }, [cal, loaded])

  useEffect(() => {
    function onCalendlyEvent(e) {
      if (isCalendlyEvent(e, 'date_and_time_selected')) {
        console.log(e.data)
      } else if (isCalendlyEvent(e, 'event_scheduled')) {
        console.log(e.data)
        onScheduled()
        // TODO: Close modal here?
      }
    }
    window.addEventListener('message', onCalendlyEvent)

    return () => {
      window.removeEventListener('message', onCalendlyEvent)
    }
  }, [])

  useEffect(() => {
    // TODO: This logic handles multiple embedded calendly modals, however,
    // we should really try to unique these so there is only one.
    if (!(window as any).calendlyLoaded) {
      ;(window as any).calendlyCallbacks = new Set()
      ;(window as any).calendlyLoaded = () => {
        for (const cb of (window as any).calendlyCallbacks) {
          cb()
        }
      }
    }

    function onCalendlyReady() {
      setLoaded(true)
    }
    ;(window as any).calendlyCallbacks.add(onCalendlyReady)

    return () => {
      ;(window as any).calendlyCallbacks.delete(onCalendlyReady)
    }
  }, [])

  // HACK! Head elements are loaded exactly with Nextjs... i.e. onLoad is not converted to 'onload' correctly
  return (
    <Fragment>
      <Head>
        {React.createElement('script', {
          onload: `window.calendlyLoaded()`,
          type: 'text/javascript',
          src: 'https://assets.calendly.com/assets/external/widget.js',
        })}
      </Head>
      {!loaded && (
        <div style={{ textAlign: 'center', height: '300px', width: '300px' }}>
          <Loader />
        </div>
      )}
      <div className={styles['container']} ref={setCal} />
    </Fragment>
  )
}
