import { AccountDNEMobilePrompt } from '@components/AccountDNEMobilePrompt/AccountDNEMobilePrompt'
import { CalendlyModal } from '@components/Calendly/CalendlyModal'
import { FlyInModalPortal } from '@components/ModalV2/modals/FlyInModal/FlyInModalPortal'
import { useHideIntercom } from '@helpers/intercom/useHideIntercom'
import { Fragment, useEffect, useState } from 'react'

declare var Intercom: any

interface MobileCalendlyModalFlowProps {
  visible: boolean
  email?: string
  onClose: () => void
}

export const MobileCalendlyModalFlow = (props: MobileCalendlyModalFlowProps) => {
  const { visible, email, onClose } = props

  const [step, setStep] = useState<'schedule-demo' | 'calendly'>('schedule-demo')

  useEffect(() => {
    setStep('schedule-demo')
  }, [visible])

  // HACK! Hide intercom
  // https://www.intercom.com/help/en/articles/189-turn-off-show-or-hide-the-intercom-messenger
  useHideIntercom(visible)

  return (
    <Fragment>
      <FlyInModalPortal
        visible={visible && step === 'schedule-demo'}
        dim={true}
        onClickOff={() => {
          onClose()
        }}
      >
        <AccountDNEMobilePrompt
          email={email}
          onClose={() => {
            onClose()
          }}
          onSchedule={() => {
            setStep('calendly')
          }}
        />
      </FlyInModalPortal>
      <CalendlyModal
        onClose={() => onClose()}
        email={email}
        visible={visible && step === 'calendly'}
      />
    </Fragment>
  )
}
