import { useEffect } from 'react'

let globalHideCount = 0

declare var Intercom: any
/**
 * Multiple components can call this hook to hide intercom.
 *
 * If ANY component has hide=true, then intercom will be hidden.
 */
export function useHideIntercom(hide: boolean) {
  // HACK! Hide intercom
  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
  // https://www.intercom.com/help/en/articles/189-turn-off-show-or-hide-the-intercom-messenger
  useEffect(() => {
    if (typeof Intercom !== 'undefined') {
      if (hide) {
        globalHideCount += 1
      }

      if (globalHideCount === 1) {
        Intercom('update', {
          hide_default_launcher: true,
        })
      }

      return () => {
        if (hide) {
          globalHideCount -= 1
        }

        if (globalHideCount === 0) {
          Intercom('update', {
            hide_default_launcher: false,
          })
        }
      }
    }
  }, [hide])
}
