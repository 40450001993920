import { FlyInModalPortal } from '@components/ModalV2/modals/FlyInModal/FlyInModalPortal'
import { CalendlyModalContents } from './CalendlyModalContents'

interface CalendlyModalProps {
  email?: string
  visible: boolean
  onClose?: () => void

  ModalPortal?: React.ComponentType<{ visible: boolean }>
}

/**
 * Calendly modal is uniqued. The first one mounted handles the modal.
 * @param props
 * @returns
 */
export function CalendlyModal(props: CalendlyModalProps) {
  const { ModalPortal = FlyInModalPortal, onClose, email, visible } = props

  return (
    <ModalPortal visible={visible}>
      <CalendlyModalContents onClose={onClose} email={email} />
    </ModalPortal>
  )
}
