import Button from '@components/button/Button'
import { MobileCalendlyModalFlow } from '@partials/auth/MobileCalendlyModalFlow'
import { Fragment, useState } from 'react'
import { trackEvent } from '@helpers/analytics/trackEvent'

interface ScheduleADemoCTAButtonProps {}

export const ScheduleADemoCTAButton = (props: ScheduleADemoCTAButtonProps) => {
  const [schedule, setSchedule] = useState(false)

  return (
    <Fragment>
      <MobileCalendlyModalFlow
        visible={schedule}
        onClose={() => setSchedule(false)}
        email=""
      />
      <Button
        btnType="lpCta"
        onClick={() => {
          try {
            trackEvent('Clicked Schedule a demo')
          } catch {}
          setSchedule(true)
        }}
      >
        Schedule a demo
      </Button>
    </Fragment>
  )
}
