import { FlyInModalPortal } from '@components/ModalV2/modals/FlyInModal/FlyInModalPortal'
import { scls } from '@helpers/scls'
import { useEffect, useState } from 'react'
import { CalendlyEmbed } from './CalendlyEmbed'
import styles from './CalendlyModal.module.css'

interface CalendlyModalContentsProps {
  email?: string

  onClose?: () => void
}

export const CalendlyModalContents = (props: CalendlyModalContentsProps) => {
  const { email, onClose } = props

  const [isScheduled, setIsScheduled] = useState(false)

  return (
    <div className={scls(styles, 'container')}>
      <div className={styles['calendly']}>
        <CalendlyEmbed email={email} onScheduled={() => setIsScheduled(true)} />
      </div>
      <div
        className={scls(styles, 'back', isScheduled && 'scheduled')}
        onClick={() => {
          onClose?.()
        }}
      >
        <span>{isScheduled ? 'Return to NextStage' : 'Cancel'}</span>
      </div>
    </div>
  )
}
