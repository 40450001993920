import { createCalendlyLink } from './calendly-utils'
import styles from './CalendlyLinkButton.module.css'

interface CalendlyButtonProps {
  // This will prepopulate the email on calendly.
  email?: string

  className?: string

  onClick?: (e: { preventDefault: () => void }) => void

  children: React.ReactNode

  disabled?: boolean
}

/**
 *
 * @param email email to be prepopulated on calendly
 * @param className completely override styles for button
 */
export const CalendlyButton = (props: CalendlyButtonProps) => {
  const { onClick, email, className, children, disabled } = props

  return (
    <a
      onClick={(e) => {
        if (disabled) {
          e.preventDefault()
        } else {
          onClick?.(e)
        }
      }}
      href={disabled ? '' : createCalendlyLink({ email: email })}
      rel="nofollow"
      className={className || styles['button']}
    >
      {children}
    </a>
  )
}
