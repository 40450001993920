// https://help.calendly.com/hc/en-us/articles/226766767-Pre-populate-invitee-information-on-the-scheduling-page
export function createCalendlyLink(options: { email?: string }) {
  const { email } = options
  const url = new URLSearchParams()
  if (email) {
    url.set('email', email)
  }

  url.set('hide_gdpr_banner', '1')

  return `https://calendly.com/rye-j/30min?${url.toString()}`
}
