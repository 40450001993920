import { CalendlyButton } from '@components/Calendly/CalendlyLinkButton'
import { CalendlyModal } from '@components/Calendly/CalendlyModal'
import { trackEvent } from '@helpers/analytics/trackEvent'
import { Fragment, useEffect, useMemo, useState } from 'react'
import styles from './AccountDNEMobilePrompt.module.scss'
import * as yup from 'yup'

interface AccountDNEMobilePromptProps {
  email?: string
  onClose: () => void
  onSchedule: () => void
}

const validateEmail = async (email: string): Promise<boolean> => {
  const schema = yup.string().required().email()
  try {
    await schema.validate(email)
    return true // Email is valid
  } catch (error) {
    return false // Email is not valid
  }
}

const isGenericEmail = (email?: string): boolean => {
  if (!email) return false
  const genericDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'aol.com',
    'icloud.com',
    'mail.com',
    'yandex.com',
    'protonmail.com',
    'zoho.com',
  ]

  const emailDomain = email.split('@')[1] ?? ''
  return genericDomains.includes(emailDomain.toLowerCase())
}

/**
 * Account {Does Not Exist} Mobile Prompt
 *
 * @param onClose if onClose is available, 'go back' will be available at the bottom
 * @returns
 */
export const AccountDNEMobilePrompt = (props: AccountDNEMobilePromptProps) => {
  const { onClose, email, onSchedule } = props

  const [editableEmail, setEditableEmail] = useState(email ?? '')

  const isEmailGeneric = useMemo(() => isGenericEmail(editableEmail), [editableEmail])
  const [isValidEmail, setIsValidEmail] = useState(false)

  useEffect(() => {
    validateEmail(editableEmail)
      .then((isValid) => setIsValidEmail(isValid))
      .catch(() => setIsValidEmail(false))
  }, [editableEmail])

  // Defaults to the input email. If the input email changes, reset to it.
  useEffect(() => {
    if (typeof email === 'string') setEditableEmail(email)
  }, [email])

  return (
    <Fragment>
      <div className={styles['container']}>
        <h2 className={styles['logo']}>See NextStage in Action</h2>

        <div className={styles['blurb']}>
          Learn how to NextStage helps you find and win government contracts.
        </div>

        <label className={'label'} htmlFor="dne-prompt-email">
          Work email
        </label>
        <input
          id="dne-prompt-email"
          className={styles['email']}
          value={editableEmail}
          onChange={(e) => setEditableEmail(e.target.value)}
        />
        <div className={styles['errorContainer']}>
          {isEmailGeneric ? (
            <div className={styles['error']}>Please use a work email address.</div>
          ) : (
            !isValidEmail &&
            editableEmail.length > 3 && (
              <div className={styles['error']}>Please enter a valid email.</div>
            )
          )}
        </div>
        <div className={styles['cta']}>
          <CalendlyButton
            email={editableEmail}
            disabled={!isValidEmail || isEmailGeneric || editableEmail.trim() === ''}
            onClick={(e) => {
              e.preventDefault()
              if (!isEmailGeneric && isValidEmail) {
                try {
                  window.analytics.identify({ email: editableEmail })

                  trackEvent('Submitted Calendly Email', {
                    email: editableEmail,
                  })
                } catch {}

                onSchedule?.()
              }
            }}
          >
            Schedule a demo
          </CalendlyButton>
        </div>
        {onClose && (
          <div className={styles['back']}>
            <span onClick={() => onClose()}>Go back</span>
          </div>
        )}
      </div>
    </Fragment>
  )
}
